<template>
    <div class="content">
		<div class="set">
			<div class="flex" style="justify-content: space-between;align-items:center;margin-bottom:20px">
				<h4 class="global_head_title">客户字段设置</h4>
				<!-- <a-button type="primary" @click="groupShow">新建分组</a-button> -->
				<a-button type="primary" @click="fieldShow">新建字段</a-button>
			</div>
			<!-- <a-modal v-model="groupVisible" :title="groupTitle" @ok="groupOk()">
				<div class="content_row flex">
					<label class="info" for="">分组名称</label>
					<div class="content_right">
						<a-input v-model="name" size="large" placeholder="请输入分组名称"/>
					</div>
				</div>
			</a-modal> -->
			<a-modal v-model="fieldVisible" :title="fieldTitle" @ok="fieldOk">
				<div class="content_row flex">
					<label class="info" for="">字段名称</label>
					<div class="content_right">
						<a-input v-model="filedName" size="large" placeholder="请输入字段名称"/>
					</div>
				</div>
				<div class="content_row flex">
					<label class="info" for="">字段类型</label>
					<div class="content_right">
						<a-select size="large" v-model="filedType" placeholder="" v-if="fieldId == 0">
							<a-select-option v-for="(value,key) in typeObj" :key="key" >
								{{ value }}
							</a-select-option>
						</a-select>
						<span v-else>{{filedTypeName}}</span>
					</div>
				</div>
				<div class="content_row flex" style="" v-if="filedType == 'radioTag' || filedType == 'checkTag' || filedType == 'select' || filedType == 'selects'">
					<label class="info" for="" v-if="filedType == 'radioTag' || filedType == 'checkTag'">标签</label>
					<label class="info" for="" v-else-if="filedType == 'select' || filedType == 'selects'">选项</label>
					<!-- <div class="set_" > -->
						<div class="reasonBg">
							<draggable v-model="tags" animation="1000" handle=".move" draggable=".set_con">	
								<div class="set_con" v-for="(item,index) in tags" :key="index">
									<input type="text" placeholder="" v-model="item.value">
									<img src="@/assets/imgs/del.png" alt="删除" @click="del(index)" v-if="tags.length != 1">
									<img class="move" src="@/assets/imgs/move.png" alt="移动"  @start="onStart" @end="onEnd" >
								</div>
							</draggable>
							<div><a-button class="add" type="link" @click="add"><img src="@/assets/imgs/add.png" alt="添加">添加</a-button></div>
						</div>
					<!-- </div> -->
				</div>
			</a-modal>
			<a-table :columns="columns" :data-source="data" class="sort-table" :pagination="false" :rowKey='record=>record.id'>
				<a-switch slot="status" slot-scope="text,record" v-model="record.status" @click="setStatus(record.id,record.status);"/>
				<a-switch slot="addshow" slot-scope="text,record" v-model="record.addshow" @click="setAddShow(record.id,record.addshow);"/>
				<template slot="Action" slot-scope="text,record">
					<a class="action" href="javascript:;" @click="editField(record.id)">编辑</a>
					
					<a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
						<a v-if="record.system == 0" class="action del" href="javascript:;">删除</a>
					</a-popconfirm>
				</template>
			</a-table>
		</div>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime} from '../../../assets/js/request';
import draggable from 'vuedraggable'
import Sortable from 'sortablejs';
export default {
	components: {
		draggable,
		// Sortable
	},
    data() {
        return {
            data: [],
            columns: [
				{ title: '字段名称', dataIndex: 'item'},
				{ title: '字段类型', dataIndex: 'type'},
				{ title: '状态', dataIndex: 'status',scopedSlots: { customRender: 'status' }},
				{ title: '添加时显示', dataIndex: 'addShow',scopedSlots: { customRender: 'addshow' }},
				{ title: '更新时间', dataIndex: 'updated'},
				{
					title: '操作',
					width: 200,
					scopedSlots: { customRender: 'Action' },
				},
			],
			// groupVisible: false,
			fieldVisible: false,
			// groupTitle: "新建分组",
			fieldTitle: "",

			filedName: "",
			filedType: "",
			filedTypeName: "",
			tags: [],
			
			typeObj: {},
			type: 1,
			fieldId: 0
        };
    },
    mounted: function(){
        this.getList();
        this.getType();

		this.rowDrop();
    },
    methods:{
		rowDrop() {
			const tbody = document.querySelector(".sort-table tbody");
			const that = this;
			Sortable.create(tbody, {
				handle:'.ant-table-row',
				animation: 180,
				delay: 0,
				onEnd({newIndex, oldIndex}) {
					let id = that.data[oldIndex].id;
					let sort = that.data[newIndex].sort;
					// console.log(newIndex, oldIndex)
					requestXml("/scrm/Settingcorp/setSort","POST",(res) => {
						if(res == "success"){
							that.getList();
						}
					},{"id":id,"sort":sort})
				}
			});
		},
		
		// 获取字段列表
        getList(){
            requestXml("/scrm/Settingcorp/getList","GET",(res) => {
                // console.log(res)
				for(let i=0;i<res.list.length;i++){
					res.list[i].updated = getDateTime(res.list[i].updated);
					res.list[i].status = Boolean(res.list[i].status);
				}
				this.data = res.list;
				console.log(this.data,8888888)
            })
        },
		// 启用禁用
		setStatus(id,status){
			console.log(id,status)
			requestXml("/scrm/Settingcorp/setStatus","POST",(res) => {
				console.log(res)
            },{"id":id,"status": Number(status)})
		},
		// 添加客户时显示
		setAddShow(id,status){
			console.log(id,status)
			requestXml("/scrm/Settingcorp/setAddShow","POST",(res) => {
				console.log(res)
            },{"id":id,"status": Number(status)})
		},
		// 获取字段类型
		getType() {
            requestXml("/scrm/Settingcorp/getFieldType","GET",(res) => {
				this.typeObj = res;
				this.filedType = Object.keys(res)[0];
            })
        },
		// groupShow(){
		// 	this.groupVisible = true;
		// },
		// groupOk(){
        //     if(!this.name){
        //         this.$message.warning('请输入分组名称');
        //         return false;
        //     }
		// 	this.groupVisible = false;
		// 	var obj = {"name": this.name, "status": NaN}
        //     this.data.push(obj);
        // },
		// 如果是新建字段 把编辑还是新建类型置为1 字段id置空 字段类型置为第一个 名称置空
		fieldShow(){
			this.fieldVisible = true;
			this.type = 1;
			this.fieldId = "",
			this.filedType = Object.keys(this.typeObj)[0];
			this.filedName = "";
			this.tags = [{"value": ""}];
			this.fieldTitle = "新建字段";
		},
		// 选项拖拽
		onStart() {
			this.drag = true;
		},
		onEnd() {
			this.drag = false;
		},
		// 选项添加
		add(){
			console.log(this.tags)
			var obj = {"value": ""};
			this.tags.push(obj);
		},
		// 选项删除
		del(index){
			console.log(index)
			this.tags.splice(index,1)
			if(index == 0){
				return false;
			}
		},
		// 添加
		fieldOk(){
			let itemList=[];
			for(let i in this.data){
				itemList.push(this.data[i].item);
			}
			// console.log(itemList);
            if(!this.filedName){
                this.$message.warning('请输入字段名称');
                return false;
            }
			if(this.type == 1){
				if(itemList.indexOf(this.filedName)!=-1){
					this.$message.warning('[ '+this.filedName+' ]字段已存在');
					return false;
				}
			}
			
            if(!this.filedType){
                this.$message.warning('请选择字段类型');
                return false;
            }

			this.fieldVisible = false;
			var url;
			if(this.type == 1){
				url = "/scrm/Settingcorp/add";
			}else{
				url = "/scrm/Settingcorp/edit";
			}
			requestXml(url,"POST",(res) => {
				console.log(res)
				this.getList();
            },{ "id": this.fieldId,
				"item": this.filedName,
				"type": this.filedType,
				"options": this.tags,
				"ault": "",
				"enditable":"",
				"addshow": 1})
        },
		// 编辑
		editField(id){
			this.fieldId = id;
			this.type = 2;
			this.fieldVisible = true;
			this.fieldTitle = "编辑字段";
			requestXml("/scrm/Settingcorp/details","POST",(res) => {
				console.log(res)
				this.filedName = res.item;
				this.filedType = res.type;
				this.filedTypeName = res.type_name;
				this.tags = res.options
            },{"id": id})
		},
		// 删除字段
		onDelete(id){
			requestXml("/scrm/Settingcorp/del","POST",(res) => {
				console.log(res)
				this.getList();
            },{"id": id})
		}
    },
};
</script>
<style scoped>
	.ant-modal{
		width: 600px !important;
	}
	.content{
		text-align: left;
	}
	.action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
	
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
	.del:hover{
		color: #FF4C4C;
	}
	
	/* 添加字段 */
	.content_row{
		margin: 15px 0 30px;
		align-items: baseline;
	}
	.content_row .info{
		width: 90px;
		padding-right: 10px;
	}

	.content_row .ant-input,.content_row .ant-select{
		width: 400px;
	} 
	.content_row .reasonInput{
		width: 400px;
		height: 40px;
		line-height: 40px;
		background: #FFF;
		border: 1px solid #D1D1D1;
		border-radius: 6px;
		padding-left: 15px;
		margin-top: 5px;
	}
	.content_row .reasonBg input{
		width: 320px;
		height: 36px;
		line-height: 36px;
		margin: 0 auto;
		padding-left: 10px;
	}
	.content_row .set_con{
		text-align: center;
		width: 400px;
		height: 50px;
		line-height: 50px;
		background: #F3F3F3;
		border-radius: 2px;
		margin-bottom: 14px;
	}
	.content_row img{
		width: 22px;
		height: 22px;
		margin-left: 10px; 
	}
</style>
